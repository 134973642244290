import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { PaginationQuery } from '../../_shared/models/issuing.models';
import { CreateOrderUPSCommand, UpdateELicenseOrderStatusCommand } from '../models/e-license';
import { IntLicensesSearchQuery, IntLicensesVm } from '../models/int-license';

@Injectable()
export class ELicensesService {
    constructor(private api: ApiHandler) { }

    updateOrderStatus(cmd: UpdateELicenseOrderStatusCommand) {
      console.log(cmd)
        return this.api.update(EndPoint.E_lICENSE_ORDER_STATUS, cmd).toPromise();
    }

    getLicenses(pagination: PaginationQuery, query: string = '') {
        return this.api.get<IntLicensesVm>(EndPoint.INT_lICENSE_DEATILS, null, { ...pagination, query, isELicense: true });
    }

    search(pagination: PaginationQuery, query: IntLicensesSearchQuery) {
        return this.api.get<IntLicensesVm>(EndPoint.INT_lICENSE_SEARCH, null, { ...pagination, ...query, isELicense: true });
    }
    createUpsShipment(cmd: CreateOrderUPSCommand) {
        return this.api.create(EndPoint.ISSUING_CREATE_UPS_SHIPMENT, cmd).toPromise();
    }
}
