import { BloodGroup } from 'src/app/core/models/enums/blood-group';
import { Gender } from 'src/app/core/models/enums/gender';
import { IntLicensePermitClass } from 'src/app/core/models/enums/license-permit-class';
import { PrintLogDto } from '../../_shared/models/issuing.models';
import { SafeUrl } from '@angular/platform-browser';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { ELicenseOrderDto } from './e-license';

export interface IntLicenseVm {
    intLicense: IntLicenseDetailsDto //todo: rename to item
}

export interface IntLicensesVm {
    list: IntLicenseDto[],
    recordsCount: number
}

export interface SerialHashVm {
    value: string
}



export interface IntLicenseDto {
    id: string,
    serial: number,
    name: string,
    gender: Gender,
    phoneNumber: string,
    accountId: number,
    accountTitles: string[],
    passportID: string,
    issued?: Date,
    printLogs: PrintLogDto[],
    revokeRequestId?: number,
    parentAccounts: LookupDto,
    accountTypeTitle: string
    isELicense: boolean;
    validityYears: number;
    eLicenseOrder?: ELicenseOrderDto;
}

export class IntLicenseDetailsDto {
    id: string;
    serial: number;
    name: string;
    dateOfBirth: Date;
    nationalityId: number;
    nationalityTitles: string[] = [];
    countryId: number;
    countryTitles: string[] = [];
    accordingToId: number;
    accordingToTitles: string[] = [];
    passportID: string;
    bloodGroup: BloodGroup;
    gender: Gender;
    permitClasses: IntLicensePermitClass[] = [];
    phoneNumber: string;
    issued?: Date;
    expiryDate?: Date;
    hashedSerial?: string;
    accountId: number;
    // personalPhoto: string;
    // passportPhoto: string;
    // licensePhoto: string;
    attachments: string[] | SafeUrl[];
    printLogs: PrintLogDto[];
    validityYears: number;
    isELicense: boolean;
    eLicenseOrder?: ELicenseOrderDto;
    documentTemplateVersion: number;

}

export interface IntLicensesSearchQuery /* extends PaginationQuery */ {
    name?: string,
    countryId?: number,
    nationalityId?: number,
    gender?: Gender,
    phoneNumber?: string,
    passportID?: string,
    accountId?: number,
    permitClass?: string,
    serial?: number,
    fromDate?: string,
    toDate?: string,
    isIssued?: boolean,
    isDamaged?: boolean,
    isELicense?: boolean,
    paymentMethod?: string,
    orderNo?: number,
    shippingStatue?: number,
    licensePeriod?: number,
}

